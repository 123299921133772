import React  from 'react';
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/fcb_small.mp4";
import projImg2 from "../assets/img/sw_small.mp4";
import projImg3 from "../assets/img/fida_small.mp4";
import projImg4 from "../assets/img/psu_426x240.webp";
import projImg5 from "../assets/img/hra.webp";
import projImg6 from "../assets/img/sandbox.webp";

import projImg7 from "../assets/img/chopshop.webp";
import projImg8 from "../assets/img/POS.webp";


import projImg9 from "../assets/img/couch-counseling.webp"
import projImg10 from "../assets/img/hra-site.webp"
import projImg11 from "../assets/img/parthificial.webp";
import projImg12 from "../assets/img/fundamentpy.webp";
import projImg13 from "../assets/img/fundamentrkt.webp";
import projImg14 from "../assets/img/gpubot.webp"
import projImg15 from "../assets/img/mapper.webp"
import projImg16 from "../assets/img/hk-site.png"
import projImg17 from "../assets/img/esc.png"
import projImg18 from "../assets/img/4tk.png"
import projImg19 from "../assets/img/anithera.png"

import get_top from '../assets/modals/get_top.png';
import get_last from '../assets/modals/get_last.png';
import graph from '../assets/modals/graph.png';
import fcb_rank_card from '../assets/modals/fcb_rank_card.png';
import wr from '../assets/modals/wr.png';
import sw_bl from '../assets/modals/sw_bl.png'
import get_all from '../assets/modals/get_all.png';
import rank_card from '../assets/modals/rank_card.png';
import inrole from '../assets/modals/inrole.png';

import 'animate.css';
// eslint-disable-next-line 
import TrackVisibility from 'react-on-screen';

let fcb = [get_top, get_last, fcb_rank_card, wr]
let sw = [sw_bl]
let fc = [get_all, graph, rank_card, inrole]

export const Projects = () => {

  const projects = [
    {
      // title: <a target="_blank" href="https://discord.com/api/oauth2/authorize?client_id=977343050651168788&permissions=439429229680&scope=applications.commands%20bot" rel="noreferrer" style={{color: 'white', textDecoration: 'none'}}>ForzaCompBot</a>,
      title: "ForzaCompBot",
      description: "A Discord bot that provides Forza Horizon 3, 4, and 5 World Records via simple commands. Without official APIs, the community compiled records into a Google Sheet. I built an API around this sheet to deliver user-friendly data. The bot also includes features from the FIDA Core bot, supporting the competitive ranking system for FIDA communities.",
      imgUrl: projImg1,
      images: fcb
    },
    {
      title: "StreetBot",
      description: "The Street Wars Bot manages the Street Wars server and automates the Blacklist section, which is a PvP battle system, for the Forza Horizon Street Wars Community. It updates, monitors, and handles Blacklist races and rankings, ensuring fair play and maintaining competitiveness. By streamlining management tasks, it allows community managers to focus on other duties, enhancing the overall community experience.",
      imgUrl: projImg2,
      images: sw
    },
    {
      title: "FIDA Core",
      description: "The FIDA Core ELO Discord Bot features a robust ELO ranking system, allowing members to track their progress and view leaderboards. It includes commands to check ranks, view top players, and see nearby ELO ranks, enhancing the competitive experience for all members. It currently has over twenty thousand users.",
      imgUrl: projImg3,
      images: fc
    },
    {
      title: "PSU Verification",
      description: " A Discord bot with Python to validate incoming users into the PSU Abington Computer Science Server. Users would invoke the validate command with their PSU Email and then the bot would cross reference the directory to confirm that the student is an active PSU Student at Abington. Recent modifications include the implementation of the Canvas LMS API, where students can get updates on assignments and announcements.",
      imgUrl: projImg4,
    },
    {
      title: "HRA Submission Bot",
      description: "Discord bot for the Forza Horizon Racing Academy community which uses Optical Character Recognition (OCR) to read race submissions and grant members roles depending on their times.",
      imgUrl: projImg5,
    },
    {
      title: "Sandbox Bot",
      description: "All in one discord bot for personal use which displays daily weather, sends Canvas announcements and assignment notifications, along with file conversion, server health, development sandbox and more.",
      imgUrl: projImg6,
    },
  ];

  const app_projects = [
    {
      title: "Pizza Shop POS",
      description: "A full stack Java Desktop App which is a fully flegded Point-Of-Sale application for cashiers",
      imgUrl: projImg8,
    },
    {
      title: "Fundament Python",
      description: "A language on top of Python for new programmers that processes plain text and executes functions using English",
      imgUrl: projImg12,
    },
    {
      title: "Fundament Racket",
      description: "A language on top of Racket/Scheme for new programmers that processes plain text and executes functions using English",
      imgUrl: projImg13,
    },
    {
      title: "Message Mapper",
      description: "Stores text messages efficiently by creating a hashmap to store words and occurrences, allowing for deconstruction and reconstruction",
      imgUrl: projImg15,
    },
    {
      title: "Parthificial Intelligence",
      description: "Team developed a Python-based NLP chatbot to answer PSU FAQs for CMPSC and IST students",
      imgUrl: projImg11,
    },
    {
      title: "GPU Hunter",
      description: "Tracked graphics card prices from multiple retailers' websites, sending notifications via email or text message when the price hits a specific price",
      imgUrl: projImg14,
    },
  ];

  const web_projects = [
    {
      title: <a target="_blank" href="https://couch-counseling.netlify.app/" style={{color: 'white', textDecoration: 'none'}} rel="noreferrer">Couch Counseling</a>,
      description: "An interactive web game for psychology students to learn how to converse with clients and how to handle certain situations",
      imgUrl: projImg9,
    },
    {
      title: <a target="_blank" href="https://horizonracingacademy.com/" style={{color: 'white', textDecoration: 'none'}} rel="noreferrer">Horizon Racing Academy</a>,
      description: "A WordPress framework for the Horizon Racing Academy. This site is for a racing learning academy to learn racecraft. Built in collaboration with Cast Haste",
      imgUrl: projImg10,
    },
    {
      // title: <a target="_blank" href="https://bluprnt.herokuapp.com/" style={{color: 'white', textDecoration: 'none'}} rel="noreferrer">Chop Shop</a>,
      title: 'Chop Shop',
      description: "A frequent shoppers best friend. A companion app which shows you the fastest way throughout a store based on a shopping list",
      imgUrl: projImg7,
    },
    {
      title: <a target="_blank" href="https://www.hkimmigrationgroup.com/" style={{color: 'white', textDecoration: 'none'}} rel="noreferrer">HK Immigration LLC</a>,
      description: "A squarespace website built for an immigration law firm. This site was built by two developers, where I focused on layout and responsiveness.",
      imgUrl: projImg16,
    },
    {
      title: <a target="_blank" href="https://escuderoelectrical.net/" style={{color: 'white', textDecoration: 'none'}} rel="noreferrer">Escudero Electrical LLC</a>,
      description: "A custom ReactJS website built for an electrical contracting group. This was a solo developer job, where I worked to build a responsive and visually appealing website.",
      imgUrl: projImg17,
    },
    {
      title: <a target="_blank" href="https://4tekies.com/" style={{color: 'white', textDecoration: 'none'}} rel="noreferrer">4Tekies LLC</a>,
      description: "A custom ReactJS website built for a technical solutions firm. The client asked for a refresh of their outgoing angular website, and I implemented a modernized ReactJS version.",
      imgUrl: projImg18,
    },
    {
      title: <a target="_blank" href="https://anithera.com/" style={{color: 'white', textDecoration: 'none'}} rel="noreferrer">Anithera</a>,
      description: "A temporary template ReactJS site for a medical devices company. This site will change soon once I move forward with the client.",
      imgUrl: projImg19,
    },

  ];


  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <h2>Projects</h2>
            <p>Here you will find all of my projects. Most of them are primarily built with Python in the backend, while the websites are either React or HTML based.</p>
            <Tab.Container id="projects-tabs" defaultActiveKey="first">
              <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                <Nav.Item>
                  <Nav.Link eventKey="first">Discord</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">Apps</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="third">Websites</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content id="slideInUp">
                <Tab.Pane eventKey="first">
                  <Row>
                    {/* Render ProjectCards for Discord projects */}
                    {projects.map((project, index) => (
                      <ProjectCard key={index} {...project} />
                    ))}
                  </Row>
                  <p>Notable functions and integrations are MongoDB, Asyncronous Python Programming, Google Sheets API, Pandas, Daily Looping Scripts, ELO Management, Rankings, User Role Lookup, Inter-Server commmunication, Database Cross Checking and more.</p>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <Row>
                    {/* Render ProjectCards for App projects */}
                    {/* Assuming app_projects are defined */}
                    {app_projects.map((project, index) => (
                      <ProjectCard key={index} {...project} />
                    ))}
                  </Row>
                  <p>These projects were my first impression of coding in a high pressure environment and delivering a large deliverable. These projects have very complex backend, where they have extreme potential if I were to pick them up again.</p>
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  <Row>
                    {/* Render ProjectCards for Website projects */}
                    {/* Assuming web_projects are defined */}
                    {web_projects.map((project, index) => (
                      <ProjectCard key={index} {...project} />
                    ))}
                  </Row>
                  <p>Although web development isn't my top skill, this allows me to spread my wings a little and learn the process of developing a site, while also keeping it a hobby project.</p>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Projects;