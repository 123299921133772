import React  from 'react';
import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import 'animate.css';
// import TrackVisibility from 'react-on-screen';
// import sgMail from '@sendgrid/mail';

// const sgMail = require('@sendgrid/mail');

// sgMail.setApiKey(process.env.SENDGRID_API_KEY);

export const Contact = () => {
  const formInitialDetails = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  }
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  // eslint-disable-next-line
  const [buttonText, setButtonText] = useState('Send');
  // eslint-disable-next-line
  const [status, setStatus] = useState({});
 
  // eslint-disable-next-line
  const onFormUpdate = (category, value) => {
      setFormDetails({
        ...formDetails,
        [category]: value
      })
  }
  
  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          {/* <Col size={12} md={6}> */}
            {/* <TrackVisibility>
              {({ isVisible }) =>
                <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={contactImg} alt="Contact Us"/>
              }
            </TrackVisibility> */}
          {/* </Col> */}
          <Col size={12} md={6}>
            {/* {/* <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}> */}
                <h2>Get In Touch</h2>
                {/* <h3>Feel free to contact me at my email at: <a href="mailto:gabenulman@gmail.com">gabenulman@gmail.com</a></h3> */}
                {/* <form onSubmit={handleSubmit} > */}
                {/* <form encType='application/x-www-form-urlencoded' method='POST' action='/contact/sendmail.php'> */}
                <form>
                  <Row>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" value={formDetails.firstName} placeholder="First Name" onChange={(e) => onFormUpdate('firstName', e.target.value)} />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" value={formDetails.lastName} placeholder="Last Name" onChange={(e) => onFormUpdate('lastName', e.target.value)}/>
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="email" value={formDetails.email} placeholder="Email Address" onChange={(e) => onFormUpdate('email', e.target.value)} />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="tel" value={formDetails.phone} placeholder="Phone No." onChange={(e) => onFormUpdate('phone', e.target.value)}/>
                    </Col>
                    <Col size={12} className="px-1">
                      <textarea rows="6" value={formDetails.message} placeholder="Message" onChange={(e) => onFormUpdate('message', e.target.value)}></textarea>
                      {/* <button type="submit"><span>{buttonText}</span></button> */}
                      {/* <button type="submit" onClick={sendEmail()}><span>{buttonText}</span></button> */}
                      <button onClick={() => window.location.href = `mailto:gabenulman+website@gmail.com?&body=${encodeURIComponent(formDetails.message)}`}>Send email</button>

                    </Col>
                    {
                      status.message &&
                      <Col>
                        <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
                      </Col>
                    }
                  </Row>
                </form>
              {/* </div>}
            </TrackVisibility> */}
          </Col>
        </Row>
      </Container>
    </section>
  )
}
